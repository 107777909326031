import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";

export const fecthLogin = ( {email, password}, language ) => {
  const output = `?lang=`;
  return Axios.post(`${ENDPOINTS.AUTH.LOGIN}`+output+language,{ email, password },{
      headers: new Headers({
        //"Auth-Token": getAuth(),
        "Content-Type": "application/json"
      }),
      credentials: "same-origin"
    }
  );
};

export const fecthResetPassword = ({ email, token },language) => {
  const output = `?lang=`;
  return Axios.post(
    `${ENDPOINTS.AUTH.RESET_PASSWORD}`+output+language,
    { email, 'gRecaptchaResponse' :token },
    {
      headers: new Headers({
        //"Auth-Token": getAuth(),
        "Content-Type": "application/json"
      }),
      credentials: "same-origin"
    }
  );
};

export const fecthChangePassword = ({ identifier, password }, language) => {
  const output = `?lang=`;
  return Axios.post(
    `${ENDPOINTS.AUTH.CHANGE_PASSWORD}`+output+ language,
    { identifier, password },
    {
      headers: new Headers({
        //"Auth-Token": getAuth(),
        "Content-Type": "application/json"
      }),
      credentials: "same-origin"
    }
  );
};

export const fetchSetupTwoFactor = (data) =>{
  return Axios.post(`${ENDPOINTS.AUTH.TWO_FACTOR_SETUP}`,
    data,
    {
      headers: {
        Authorization:'Bearer ',
        "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }
  )
}

export  const fetchVerifyTwoFactor =(data) =>{
  return Axios.post(
    `${ENDPOINTS.AUTH.TWO_FACTOR_VERIFY}`,
    data,
  {
    headers: new Headers({
      //"Auth-Token": getAuth(),
      "Content-Type": "application/json"
    }),
      credentials: "same-origin"
  }
  )
}

export const fetchLoginWith2FA = (data) => {
  return Axios.post(`${ENDPOINTS.AUTH.LOGIN}`,
  {
    email:data.email,
    code2fa:data.code2fa,
    password:data.token,
  },
  {
    headers:{
      "Content-Type":"application/json"
    },
    credentials: "same-origin"
  }
  );
};

export const fetchCurrentAccount = (token) => {
  return Axios.get(`${ENDPOINTS.AUTH.CURRENT_USER}`,
    {
      headers: {
        Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }
  );
};

export const fetchPaymentsType = (token) => {
  return Axios.get(`${ENDPOINTS.AUTH.PAYMENT_TYPE}`,
    {
      headers: {
        Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }
  );
};

export const fetchPaymentsErrorType = (token) => {
  return Axios.get(`${ENDPOINTS.AUTH.PAYMENT_ERROR_TYPE}`,
    {
      headers: {
        Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }
  );
};


export const fetchRefreshToket = (token) => {
  return Axios.get(`${ENDPOINTS.AUTH.REFRESH_TOKEN}`,
    {
      headers: {
        Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }
  );
};
