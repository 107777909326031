const AUTH = {
    BASIC: {
        SET_STATE: "user/SET_STATE"
    },
    ASYNC: {
        LOGIN: "user/LOGIN",
        LOGOUT: "user/LOGOUT",
        REFRESH_TOKEN: "user_async/REFRESH_TOKEN",
        RESET_PASSWORD: "user/RESET_PASSWORD",
        CHANGE_PASSWORD: "user/CHANGE_PASSWORD",
        TWO_FACTOR_REQUEST: "user/TWO_FACTOR_REQUEST",
        SET_QR_URL:"user/SET_QR_URL",
        SET_KEY:"user/SET_KEY",
        LOGIN_WITH_2FA_REQUEST:"user/LOGIN_WITH_2FA_REQUEST",
        TWO_FACTOR_SUCCESS: "user/TWO_FACTOR_SUCCESS",
        TWO_FACTOR_FAILURE: "user/TWO_FACTOR_FAILURE",
        TWO_FACTOR_VERIFY_REQUEST: "user/TWO_FACTOR_VERIFY_REQUEST",
        TWO_FACTOR_VERIFY_SUCCESS: "user/TWO_FACTOR_VERIFY_SUCCESS",
        TWO_FACTOR_VERIFY_FAILURE: "user/TWO_FACTOR_VERIFY_FAILURE",
        CLEAR_2FA_ERROR:"user/CLEAR_2FA_ERROR",
        SET_BASIC_DATA_2FA:"user/SET_BASIC_DATA_2FA",
    },
    WORKER: {
        LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
        LOAD_LOGOUT: "user/LOAD_LOGOUT",
        REFRESH_TOKEN: "user/REFRESH_TOKEN"
    }
};

const THEME = {
    ASYNC: {
        GET: "theme/GET_THEME"
    },
    WORKER: {
        LOAD_CURRENT_THEME: "theme/LOAD_CURRENT_THEME"
    }
}

const ANALITYCS = {
    ASYNC: {
        GET_STATUS_OVER_TIME: "analitycs/GET_STATUS_OVER_TIME",
        GET_STATUS_MONEY: "analitycs/GET_STATUS_MONEY",
        GET_STATUS_REJECT: "analitycs/GET_STATUS_REJECT",
        GET_STATUS_APP_CODE: "analitycs/GET_STATUS_APP_CODE",
        GET_STATUS_CARRIER: "analitycs/GET_STATUS_CARRIER",
        GET_STATUS_ACTIVITY: "analitycs/GET_STATUS_ACTIVITY",
    },
    BASIC: {
        INIT: "analitycs/INIT_ANALITYCS",
        SET_STATE_BAR: "analitycs/SET_STATE_BAR",

    },
    WORKER: {
        SET_STATUS_OVER_TIME: "analitycs/SET_STATUS_OVER_TIMEW",
        SET_STATUS_MONEY: "analitycs/SET_STATUS_MONEY",
        SET_STATUS_REJECT: "analitycs/SET_STATUS_REJECT",
        SET_STATUS_APP_CODE: "analitycs/SET_STATUS_APP_CODE",
        SET_STATUS_CARRIER: "analitycs/SET_STATUS_CARRIER",
        SET_STATUS_ACTIVITY: "analitycs/SET_STATUS_ACTIVITY",
    }
}

const MESSAGES = {
    ERROR: "message/ERROR"
};

const TRANSACTIONS = {
    ASYNC: {
        GET_TRANSACTIONS: "transactions/GET_TRANSACTIONS",
        GET_REFUND_TRANSACTION: "transaction/GET_REFUND_TRANSACTION",
        GET_LOG_TRANSACTION: "transaction/GET_LOG_TRANSACTION",
        GET_STATUS_HISTORY: "transaction/GET_STATUS_HISTORY",
        GET_PDF_TRANSACTION: "transacion/GET_PDF_TRANSACTION",
    },
    BASIC: {
        INIT: "transactions/INIT_TRANSACTIONS",
        INIT_REFUND_TRANSACTION: "transaction/INIT_REFUND_TRANSACTION",
        INIT_LOG_TRANSACTION: "transaction/INIT_LOG_TRANSACTION",
    },
    WORKER: {
        SET_TRANSACTIONS: "transactions/SET_TRANSACTIONS",
        SET_REFUND_TRANSACTION: "transaction/SET_REFUND_TRANSACTION",
        SET_LOG_TRANSACTION: "transaction/SET_LOG_TRANSACTION",
        SET_STATUS_HISTORY: "transaction/SET_STATUS_HISTORY",
    }
}

const REPORTS = {
    ASYNC: {
        GET_REPORTS: "reports/GET_REPORTS",
        GET_REPORTS_STATUS: "reports/GET_REPORTS_STATUS",
    },
    BASIC: {
        INIT: "reports/INIT_TRANSACTIONS",
    },
    WORKER: {
        SET_REPORTS: "reports/SET_REPORTS",
        SET_REPORTS_STATUS: "reports/SET_REPORTS_STATUS",
    }
}

const LINKTOPAY = {
  BASIC: {
    SET_LINKTOPAY: "linktopay/SET_LINKTOPAY",
    INIT: "linktopay/INIT_TRANSACTIONS",
    INIT_PAYMENT_REFERENCE: "linktopay/INIT_PAYMENT_REFERENCE"
  },
  ASYNC: {
    FORM_LINKTOPAY: "linktopay/FORM_LINKTOPAY",
    FORM_REDEBAN: "linktopay/FORM_REDEBAN",
    GET_TRANSACTIONS: "linktopay/GET_TRANSACTIONS",
    LINKTOPAY_MASSIVE: "linktopay/LINKTOPAY_MASSIVE",
    PAYMENT_REFERENCE: "linktopay/PAYMENT_REFERENCE"
  },
  WORKER: {
    LOAD_FORM: "linktopay/LOAD_FORM",
    SET_REDEBAN: "linktopay/SET_REDEBAN",
    SET_TRANSACTIONS: "linktopay/SET_TRANSACTIONS",
    SET_MASSIVE: "linktopay/SET_MASSIVE",
    SET_PAYMENT_REFERENCE: "linktopay/SET_PAYMENT_REFERENCE"
  }
}

const STATUS = {
    ASYNC: {
        GET_SERVER_STATUS: "status/GET_SERVER_STATUS",
    },
    BASIC: {
        INIT: "status/INIT_SERVER_STATUS",
    },
    WORKER: {
        SET_SERVER_STATUS: "status/SET_SERVER_STATUS",
    }
}

const REGISTERDB = {
  ASYNC: {
    GET_TYPE_COMMERCE: "register/GET_TYPE_COMMERCE",
    GET_TYPE_DOC: "register/GET_TYPE_DOC",
    GET_LIST_DEPTO: "register/GET_LIST_DEPTO",
    GET_LIST_CITIES: "register/GET_LIST_CITIES",
    GET_QUESTIONS: "register/GET_QUESTIONS",
    CLEAN_QUESTIONS: "register/CLEAN_QUESTIONS",
    VALIDATE_QUESTIONS: "register/VALIDATE_QUESTIONS",
    NEXT_STEP: "register/NEXT_STEP",
    GET_REGISTER_USER: "register/GET_REGISTER_USER",
    GET_CATEGORY: "register/GET_CATEGORY",
    GET_BILLING: "register/GET_BILLING",
    GET_TYPE_PHONE: "register/GET_TYPE_PHONE",
    GET_BANK: "register/GET_BANK",
    GET_TYPE_ACCOUNT: "register/GET_TYPE_ACCOUNT",
    NEXT_STEP_REGISTRO: "register/NEXT_STEP_REGISTRO",
    GET_REFERIDO: "register/GET_REFERIDO",
  },
  BASIC: {
    SET_TYPE_COMMERCE: "register/SET_TYPE_COMMERCE ",
    INIT_REGISTER: "register/INIT_REGISTER ",
  },
  WORKER: {
    TYPE_COMMERCE: "register/TYPE_COMMERCE",
    TYPE_DOC: "register/TYPE_DOC",
    LIST_DEPTO: "register/LIST_DEPTO",
    LIST_CITIES: "register/LIST_CITIES",
    QUESTIONS: "register/QUESTIONS",
    REGISTER_USER: "register/REGISTER_USER"
  }
}

const LANGUAGE ={
    SWITCH_LANGUAGE: 'language/SWITCH_LANGUGE'
}

export const ACTION_TYPES = {
    MESSAGES,
    AUTH,
    THEME,
    ANALITYCS,
    TRANSACTIONS,
    REPORTS,
    LINKTOPAY,
    STATUS,
    REGISTERDB,
    LANGUAGE
};
