import { lazyLoadable } from "../../utils/lazyLoadable";

// Containers
const DefaultLayout = lazyLoadable(() =>
  import("../../layoutRouter/DefaultLayout")
);

// Pages
const Login = lazyLoadable(() => import("../../pages/Login/Login"));
const TwoFactorAuth = lazyLoadable(() => import("../../pages/TwoFactorAuth/TwoFactorAuth"));
const OtpAuth = lazyLoadable(()=> import("../../pages/TwoFactorAuth/OtpComponent"));
const Register = lazyLoadable(() => import("../../views/Register/Register"));
const ResetPassword = lazyLoadable(() =>
  import("../../pages/ResetPassword/ResetPassword")
);
const Page404 = lazyLoadable(() => import("../../views/Pages/Page404"));
const Page500 = lazyLoadable(() => import("../../views/Pages/Page500"));

const routers = [
  { path: "/", exact: true, name: "Home" },
  { path: "/two-factor", name: "TwoFactorAuth", component: TwoFactorAuth},
  { path: "/otp", name: "OtpAuth", component: OtpAuth},
  { path: "/login", name: "Login", component: Login },
  { path: "/ingreso", name: "Login", component: Login },
  { path: "/registro", name: "Register", component: Register },
  { path: "/reset-password", name: "ResetPassword", component: ResetPassword },
  {
    path: "/user-reset-password",
    name: "ChangePassword",
    component: ResetPassword,
  },
  {
    path: "/reset-password-send",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/user-reset-password-ok",
    name: "ChangePassword",
    component: ResetPassword,
  },
  {
    path: "/404",
    name: "Page 404",
    component: Page404,
  },
  { path: "/505", name: "Page 505", component: Page500 },
];

let mains = [
  { path: "/dashboard", name: "Dashboard", component: DefaultLayout },
  { path: "/transaction", name: "Dashboard", component: DefaultLayout },
  { path: "/report", name: "Dashboard", component: DefaultLayout },
  { path: "/linkToPay-create", name: "Dashboard", component: DefaultLayout },
  { path: "/linkToPay-search", name: "Dashboard2", component: DefaultLayout },
  { path: "/linkToPaySuccess", name: "Dashboard", component: DefaultLayout },
  { path: "/status", name: "Dashboard", component: DefaultLayout },
];

mains = mains.map((main) => {
  main.authTable = { login: true };
  return main;
});

export const MAIN_ROUTERS = [...routers, ...mains];
