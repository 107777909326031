import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

export class AuthRouter extends React.Component {
  render() {
    const { redux, authTable } = this.props;
    let router = <Route {...this.props}/>;
    if (!!authTable) {
      if (authTable.login) {       
        if (redux.auth.token) {
          if(redux.auth.is2FARequired ){
            if(!redux.auth.is2FACompleted){
              router = <Redirect to="two-factor"/>
            }else{
              router =<Redirect to="/otp"/>
            }        
          }
          else{
             router = <Route {...this.props}/>;
          } 
        } else {
          router = <Redirect to="/ingreso" />;
        }
      }
    } 
    return router;
  }
}

const mapStateToProps = state => ({
  redux: { ...state }
});

export const AuthRouterConnected = connect(mapStateToProps)(AuthRouter);
