import { all, takeEvery, put as putAction, call, select } from "redux-saga/effects";

import { ACTION_TYPES } from "../../constant/actionTypes";
import {
  fecthLogin,
  fecthResetPassword,
  fecthChangePassword,
  fetchCurrentAccount,
  fetchPaymentsType,
  fetchPaymentsErrorType,
  fetchRefreshToket,
  fetchSetupTwoFactor,
  fetchVerifyTwoFactor,
  fetchLoginWith2FA,
} from "../../services/authService";
import { authLoading, authLogin, verify2FASuccess, setup2FARequest,setup2FAFailure,verify2FAFailure, setQRUrl, setBasicData } from "./authActions";
import { authData } from "./authFilter";
import { translate } from "react-i18nify";
import { push } from 'connected-react-router';
import { getGlobalValue } from "../../../context/langContext";

export const getAuth = (state) => state.auth;

export function* LOGIN_SAGA({ payload, conf }) {
  const connectionLanguage = getGlobalValue();
  const { email, password, code2fa  } = payload;
  const setSubmitting = conf.setSubmitting || (x => x);
  yield putAction(authLoading(true));
  try {
    const success = yield call(fecthLogin, { email, password, code2fa}, connectionLanguage );
    if (success.data.code === 200){
      const user = yield call(authData, success.data.token);
      yield putAction(authLogin({ user: user }));
      yield putAction(authLogin(success.data));
      let info = yield call(fetchCurrentAccount, success.data.token);
      if (info.data && info.data.Permission){
        info.data.isRefund = hashPermissions(info.data.Permission, "refund");
        info.data.isAntifraud = hashPermissions(info.data.Permission, "antifraud");
      }
      yield putAction(authLogin(info.data));
      const payment = yield call(fetchPaymentsType, success.data.token);
      yield putAction(authLogin({ payments: payment.data }));
      const paymentError = yield call(fetchPaymentsErrorType, success.data.token);
      yield putAction(authLogin({ paymentsError: paymentError.data }));
    }
    else if(success.data.code === 206){
      const token = success.data.token;
      yield putAction(setup2FARequest(token, email));
    }
  } catch (error) {
    //yield putAction(messagesError(error));
    yield putAction(authLoading(false));
    if(error.response && error.response.data && error.response.data.code === 406){
      yield putAction(push('/otp'));
      yield putAction(setBasicData(payload.email, error.response.data.token))
     // yield putAction(verify2FARequest('', payload.email, error.response.data.token))
    }
    if (error.response && error.response.data && error.response.data.message) {
      conf.setErrors({
        "email": error.response.data.message,
        "password": error.response.data.message
      })
    } else {
      conf.setErrors({
        "email": translate("error_messages.unauthorized"),
        "password": translate("error_messages.unauthorized")
      })
    }
  }
  setSubmitting(false);
}

export function* SETUP2FASAGA ({payload}){
 try{
  const response = yield call(fetchSetupTwoFactor, payload);
  const {qr, key }= response.data;
  yield  putAction(setQRUrl(qr));
  yield putAction({ type: ACTION_TYPES.AUTH.ASYNC.SET_KEY, payload:{key}});
  //yield putAction(push('/two-factor'));
 } catch (error){
   yield  putAction(setup2FAFailure(error))
 }
}

const errorTranslationMap ={
  "Invalid code":"two_factor.errors.code_invalid",
  "2fa_invalid_code": "two_factor.errors.message",
}
export  function*  VERIFY2FASAGAS({payload}){
  //yield putAction(authLoading(true));
  try{
    const {code, email, key} = payload
    const response = yield call(fetchVerifyTwoFactor, {
      code:code,
      email,
      secret:key,
    })
    if(response.status === 200 ){
      yield putAction(verify2FASuccess());
      const newToken = response.data.token;  
      yield putAction(authLogin({token:newToken}));
    }
  }catch (error) {
    yield putAction(authLoading(false));
    let errorMessage = translate("two_factor.errors.generic_error");
    if(error.response){
      const servererrorMessage = error.response.data?.message || errorMessage;
      const translationKey = errorTranslationMap[servererrorMessage];
      if(translationKey){
        errorMessage = translate(translationKey);
      }else{
        errorMessage = servererrorMessage;
      }
    }else if(error.message){
      errorMessage = error.message
    }
   
    yield putAction(verify2FAFailure(errorMessage))
  }
  yield putAction(authLoading(true));
}
export function* loginWith2FASaga({payload}){
  const {code2fa, email, token} = payload;
  yield putAction(authLoading(true));
  try{
    const success = yield call(fetchLoginWith2FA, {email, code2fa, token});
    if (success.data.code === 200){
      const user = yield call(authData, success.data.token);
      yield putAction(authLogin({ user: user }));
      yield putAction(authLogin(success.data));
      let info = yield call(fetchCurrentAccount, success.data.token);
      if (info.data && info.data.Permission){
        info.data.isRefund = hashPermissions(info.data.Permission, "refund");
        info.data.isAntifraud = hashPermissions(info.data.Permission, "antifraud");
      }
      yield putAction(authLogin(info.data));
      const payment = yield call(fetchPaymentsType, success.data.token);
      yield putAction(authLogin({ payments: payment.data }));
      const paymentError = yield call(fetchPaymentsErrorType, success.data.token);
      yield putAction(authLogin({ paymentsError: paymentError.data }));
      yield putAction(push('/dashboard'));
    }else{
      console.log('Login con 2FA fallo', success.data.message);
    }
  }catch(error){
    let errorMessage = translate("two_factor.errors.generic_error");
    if(error.response){
      const servererrorMessage = error.response.data?.message || errorMessage;
      const translationKey = errorTranslationMap[servererrorMessage];
      if(translationKey){
        errorMessage = translate(translationKey);
      }else{
        errorMessage = servererrorMessage;
      }
    }else if(error.message){
      errorMessage = error.message
    }
    yield putAction(verify2FAFailure(errorMessage))
    yield putAction(authLoading(false));
    console.log('Error al intentar logear con 2FA', error)
  }
  yield putAction(authLoading(false));
}
function hashPermissions(permisos, permiso) {
  if(permisos && permisos.length > 0) {
    const lista = permisos.split(",")
    return lista.includes(permiso) ? 1 : 0
  }
  return 0
}
export function* REFRESH_TOKEN_SAGA() {
  try {
    const auth = yield select(getAuth);
    const success = yield call(fetchRefreshToket, auth.token);
    yield putAction(authLogin(success.data));
  } catch (error) {

  }
}

export function* RESET_PASSWORD_SAGA({ payload, conf }) {
  const connectionLanguage = getGlobalValue();
  const { email, token } = payload;
  const setSubmitting = conf.setSubmitting || (x => x);
  setSubmitting(true);
  try {
    yield call(fecthResetPassword, { email, token}, connectionLanguage  );
    setSubmitting(false);
    yield putAction(push('/reset-password-send'));
  } catch (error) {
    conf.setErrors({
      "email": error.response.data.Msg
    })
    setSubmitting(false);
  }
  setSubmitting(false);
}

export function* CHANGE_PASSWORD_SAGA({ payload, conf }) {
  const connectionLanguage = getGlobalValue();
  const { identifier, password } = payload;
  const setSubmitting = conf.setSubmitting || (x => x);
  setSubmitting(true);
  try {
    yield call(fecthChangePassword, { identifier, password },connectionLanguage );
    setSubmitting(false);
    yield putAction(push('/user-reset-password-ok'));
  } catch (error) {
    conf.setErrors({
      "password": error.response.data.Msg
    })
    setSubmitting(false);
  }
  setSubmitting(false);
}

/*
export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true
    }
  });
  const response = yield call(currentAccount);
  if (response) {
    const { uid: id, email, photoURL: avatar } = response;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        name: "Administrator",
        email,
        avatar,
        role: "admin",
        authorized: true
      }
    });
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false
    }
  });
}

export function* LOGOUT() {
  yield call(logout);
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false
    }
  });
}*/

export default function* AuthSaga() {
  yield all([
    takeEvery(ACTION_TYPES.AUTH.ASYNC.LOGIN, LOGIN_SAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.RESET_PASSWORD, RESET_PASSWORD_SAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.CHANGE_PASSWORD, CHANGE_PASSWORD_SAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.REFRESH_TOKEN, REFRESH_TOKEN_SAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_REQUEST,SETUP2FASAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_VERIFY_REQUEST,VERIFY2FASAGAS),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.LOGIN_WITH_2FA_REQUEST,loginWith2FASaga),
    //takeEvery(ACTION_TYPES.AUTH.ASYNC.LOGIN_WITH_2FA_REQUEST),
    //takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    //takeEvery(actions.LOGOUT, LOGOUT),
    //LOAD_CURRENT_ACCOUNT() // run once on app load to check user auth
  ]);
}
