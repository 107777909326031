import { ACTION_TYPES } from "../../constant/actionTypes";

/**
 * Action to dispatch the login to the server
 * @param {object} param0 data from form typed by the user
 */
export function authLoadAccount({ email, password }, conf = null) {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.LOGIN,
    payload: {
      email,
      password
    },
    conf
  };
}

/**
 * Action to dispatch the account authenticated
 * @param {object} success data from the server
 */
export function authLogin(success) {
  return {
    type: ACTION_TYPES.AUTH.WORKER.LOAD_CURRENT_ACCOUNT,
    payload: {
      ...success,
      email:success.email,
      password:success.password,
    }
  };
}

/**
 * Action to dispatch the logout
 * @param {object} success data from the server
 */
export function authLogout() {
  return {
    type: ACTION_TYPES.AUTH.WORKER.LOAD_LOGOUT
  };
}

/**
 * Action to dispatch the logout
 * @param {object} success data from the server
 */
export function authRefreshToken() {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.REFRESH_TOKEN
  };
}

/**
 * Action to disptach a blocking action to the Auth actions
 * @param {boolean} status data from the system to prevent extra actions on Auth
 */
export function authLoading(status = false) {
  return {
    type: ACTION_TYPES.AUTH.BASIC.SET_STATE,
    payload: {
      loading: status
    }
  };
}
export function setup2FARequest (token, email){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_REQUEST,
    payload:{token, email},
  }
}
export function setQRUrl  (qrUrl){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.SET_QR_URL,
    payload:{qrUrl}
  }
}
export function setBasicData(email, token){
  return{
    type: ACTION_TYPES.AUTH.ASYNC.SET_BASIC_DATA_2FA,
    payload:{email, token}
  }
}
export function setup2FASuccess (qrUrl){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_SUCCESS,
    payload:{
      qrUrl
    }
  }
}
export function setup2FAFailure (error){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_FAILURE,
    payload:{
      error
    }
  }
}
export function verify2FARequest (code, email, key){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_VERIFY_REQUEST,
    payload:{
      code,
      email,
      key
    }
  }
}
export function verify2FASuccess (){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_VERIFY_SUCCESS
  }
}
export function verify2FAFailure (error){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.TWO_FACTOR_VERIFY_FAILURE,
    payload:{
      error
    }
  }
}
export function clear2FAError (){
  return{
    type:ACTION_TYPES.AUTH.ASYNC.CLEAR_2FA_ERROR
  };
}
export function loginWith2FARequest(code2fa, email, token){
  return{
    type: ACTION_TYPES.AUTH.ASYNC.LOGIN_WITH_2FA_REQUEST,
    payload:{code2fa, email, token}
  }
}

export function resetPasswordAccount({ email, token }, conf = null) {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.RESET_PASSWORD,
    payload: {
      email,
      token
    },
    conf
  };
}

export function changePasswordAccount({ identifier, password }, conf = null) {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.CHANGE_PASSWORD,
    payload: {
      identifier,
      password
    },
    conf
  };
}
